import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import addToMailchimp from 'gatsby-plugin-mailchimp';

// Based on: https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/examples/src/pages/index.js
const MailchimpForm = ({ submitText, confirmationMessage }) => {
  const [state, setState] = React.useState({
    email: null,
  });
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const postSubmit = () => {
    setSubmitted(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log('submit', state);

    addToMailchimp(state.email, state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);
        if (result !== 'success') {
          throw msg;
        }
        postSubmit();
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  };

  return (
    <Form name="Newsletter Form" className='form-inline' onSubmit={handleSubmit}>
      <div className={`w-100`}>
        {!submitted && (
          <Row className={`no-gutters`}>
            <Col xs={7} md={8}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  onChange={handleChange}
                  className={`mailchimp-field w-100`}
                />
              </Form.Group>
            </Col>
            <Col xs={5} md={4}>
              <button type="submit" className="btn btn-gradient mailchimp-button w-100">
                {submitText}
              </button>
            </Col>
          </Row>
        )}

        {submitted && <h2 className="text-center">{confirmationMessage}</h2>}
      </div>
    </Form>
  );
};

MailchimpForm.propTypes = {
  submitText: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
};

export default MailchimpForm;
