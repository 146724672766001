import React from "react";
import {graphql} from "gatsby";
import {Col, Container, Row, Card} from "react-bootstrap";
import BackgroundImage from "gatsby-background-image/index";

import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderCol from '../components/headerCol/headerCol';
import MailchimpForm from '../components/mailchimpForm/mailchimpForm';
import Swirl from '../images/backgrounds/bg-swirl.svg';

const ConnectPage = ({ data }) => {
  // Map ACF group content to variable
  const content = data.wpPage.pageConnect;

  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.metaTitle}
        description={data.wpPage.seo.metaDesc}
        image={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        ogTitle={data.wpPage.seo.opengraphTitle}
        ogDescription={data.wpPage.seo.opengraphDescription}
        ogImage={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        twitterTitle={data.wpPage.seo.twitterTitle}
        twitterDescription={data.wpPage.seo.twitterDescription}
        twitterImage={data.wpPage.seo.twitterImage?.localFile.publicURL}
      />

      <BackgroundImage
        Tag="header"
        fluid={content.connectHeader.background.localFile.childImageSharp.fluid}
        fadeIn={false}
        style={{ opacity: `1 !important` }}
      >
        <Container>
          <Row>
            <HeaderCol>
              <h1>{content.connectHeader.headline}</h1>
              <p>{content.connectHeader.subheadline}</p>
            </HeaderCol>
          </Row>
        </Container>

        {/* eslint-disable jsx-a11y/alt-text */}
        <img
          src={Swirl}
          style={{opacity: 1, transform: 'translateY(1px)', height: 'auto', width: '100vw'}}
        />
        {/* eslint-enable jsx-a11y/alt-text */}
      </BackgroundImage>

      <section>
        <Container className='py-4 py-md-6'>
          <Row className="justify-content-center text-center mb-5">
            <HeaderCol>
              <h2>{content.connectSignup.callToAction.headline}</h2>
              <p>{content.connectSignup.callToAction.description}</p>
            </HeaderCol>
          </Row>

          <Row className="mb-5 align-items-center" id="subscribe">
            <Col xs={12} md={4} lg={6}>
              <h2>{content.connectSignup.subscribeForm.headline}</h2>
              <p>{content.connectSignup.subscribeForm.description}</p>
            </Col>

            <Col xs={12} md={8} lg={6}>
              <MailchimpForm confirmationMessage={'You are subscribed'} submitText={'Subscribe'}/>
            </Col>
          </Row>

          <Row id="actions">
            {content.connectActions.map((action, index) => (
              <Col md={12} lg={4} key={`action-${index}`} className="pb-3 d-flex">
                <Card className={`d-flex flex-column`}>
                  <Card.Body className="text-center pt-4 pb-5 px-2">
                    <Card.Title className="mb-5"><h3>{action.headline}</h3></Card.Title>

                    <Card.Text className="mb-5">
                      {action.description}
                    </Card.Text>

                    <a
                      href={action.link}
                      className="btn btn-gradient">{action.button}</a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 78 }) {
        seo {
            metaDesc
            metaTitle
            twitterDescription
            twitterTitle
            twitterImage {
                localFile {
                    publicURL
                }
            }
            opengraphTitle
            opengraphDescription
            opengraphImage {
                localFile {
                    publicURL
                }
            }
        }
      pageConnect {
        connectHeader {
          headline
          subheadline
          background {
            localFile {
                ...imageFluidExtraLarge
            }
          }
        }
        connectSignup {
          callToAction {
            headline
            description
          }
          subscribeForm {
            headline
            description
            button
          }
        }
        connectActions {
          headline
          description
          button
          link
        }
      }
    }
  }
`;

export default ConnectPage;
